<template>
  <BaseAlinea class="instruments">
    <template #header>Methodieken/Instrumenten </template>
    <p>
      Je EigenWijzer kijkt naar de doelen die je wilt bereiken/welke verlangens
      er zijn en welke methodes/instrumenten daarvoor kunnen worden ingezet.
      <br />Hier vind je de vormen van coaching waar Je EigenWijzer mee werkt.
    </p>
  </BaseAlinea>
</template>

<script lang="ts">
import { defineComponent, ref } from "vue";
import BaseAlinea from "@/components/base/BaseAlinea.vue";

export default defineComponent({
  components: {
    BaseAlinea
  }
});
</script>

<style scoped>
.instruments {
  margin-bottom: 2em;
}
</style>
