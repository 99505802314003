<template>
  <div class="laatje">
    <div class="laatje__heart">
      <img src="@/assets/icons/heart.svg" alt="heart icon" />
    </div>
    <div class="wrapper laatje__content">
      <h1>Laatje</h1>
      <p>
        Als ik een laatje opentrek<br />
        Gooi jij ‘m dan meteen weer dicht?<br />
        Of mag ik -heel voorzichtig- <br />Zien wat daar verborgen ligt?
      </p>
      <p>
        Ik kan heus wel tegen rommel<br />
        Van een laag stof word ik niet bang<br />
        Dat jij je gevoel het liefst verstopt<br />
        Dat weet ik toch al lang?
      </p>
      <p>
        Maar weet je wat me raakt?<br />
        Je la kan niet meer dicht <br />Het is veel teveel gevoel<br />
        Dat daar opgestapeld ligt
      </p>
      <p>
        Je kunt nog zo hard duwen<br />
        Ertegen schoppen wat je wil <br />Het zal je niet gaan lukken<br />
        Je gevoel houdt zich niet stil
      </p>
      <p>
        Mag ik alsjeblieft -voorzichtig- <br />Samen met jou proberen<br />
        Of een zoektocht door gevoel <br />Je misschien iets moois kan leren?
      </p>

      <p class="laatje__author">brievenbusgeluk.nl</p>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({});
</script>

<style lang="scss" scoped>
@import "@/global";

.laatje {
  $bg: #f1e1e6;
  position: relative;
  background: $bg;
  padding: 54px 0 17px 0;
  text-align: center;
  margin-top: 42px;

  &__heart {
    border-radius: 50%;
    background: $bg;
    position: absolute;
    top: 0;
    left: 50%;
    transform: translate(-50%, -50%);
    $size: 56px;
    width: $size;
    height: $size;
    display: flex;
    justify-content: center;
    align-content: center;

    img {
      width: 30px;
    }
  }

  p {
    margin-bottom: 22px;
    font-family: LoveYaLikeASister;
    font-size: 12px;
  }

  &__author {
    text-align: right;
    font-family: "Open Sans", sans-serif !important;
    font-size: 10px;
  }
}

@include laptop {
  .laatje {
    width: 375px;
    margin: 0 auto;

    &__content {
      width: 100%;
      padding: 0 50px;
    }
  }
}
</style>
