<template>
  <div class="methods">
    <BaseHeroImg
      imgAlt="Water met zons ondergang"
      :imgSrc="require('@/assets/images/methods-hero.png')"
    />
    <BaseHero titleAlignment="left"> Methodieken </BaseHero>

    <div v-if="methods">
      <Instruments />
      <div
        class="wrapper methods__method"
        v-for="(m, i) in methods"
        :key="m.sys.id"
        :style="i <= methodMiddle ? '' : 'display: none'"
      >
        <BaseAlinea smallHeading>
          <h1 style="font-size: 16px; margin-bottom: 0">
            {{ m.fields.title }}
          </h1>
          <p>
            {{ m.fields.desc }}
          </p>
        </BaseAlinea>
      </div>
      <Laatje class="methods__laatje" />

      <div
        class="wrapper methods__method"
        v-for="(m, i) in methods"
        :key="m.sys.id"
        :style="i > methodMiddle ? '' : 'display: none'"
      >
        <BaseAlinea smallHeading>
          <h1 style="font-size: 16px; margin-bottom: 0">
            {{ m.fields.title }}
          </h1>
          <p>
            {{ m.fields.desc }}
          </p>
        </BaseAlinea>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import BaseHero from "@/components/base/BaseHero.vue";
import BaseHeroImg from "@/components/base/BaseHeroImg.vue";
import Instruments from "@/components/methods/Instruments.vue";
import Laatje from "@/components/methods/Laatje.vue";
import { ref } from "vue";
import client from "@/hooks/useContentful";
export default {
  components: {
    BaseHero,
    BaseHeroImg,
    Instruments,
    Laatje
  },
  setup() {
    const methods = ref<any>(null);
    const methodMiddle = ref(0);

    client
      // eslint-disable-next-line @typescript-eslint/camelcase
      .getEntries({ content_type: "method" })
      .then((res) => {
        methods.value = res.items;
        methods.value.sort(
          (a: any, b: any) => a.fields.position - b.fields.position
        );
        methodMiddle.value = Math.round(methods.value.length / 2) - 1;
      })
      .catch();

    return { methods, methodMiddle };
  }
};
</script>

<style lang="scss" scoped>
.methods {
  position: relative;
  overflow-x: hidden;

  &__laatje {
    margin-top: 62px;
    margin-bottom: 2em;
  }

  &__method {
    margin-bottom: 2em;
  }
}
</style>
