
import BaseHero from "@/components/base/BaseHero.vue";
import BaseHeroImg from "@/components/base/BaseHeroImg.vue";
import Instruments from "@/components/methods/Instruments.vue";
import Laatje from "@/components/methods/Laatje.vue";
import { ref } from "vue";
import client from "@/hooks/useContentful";
export default {
  components: {
    BaseHero,
    BaseHeroImg,
    Instruments,
    Laatje
  },
  setup() {
    const methods = ref<any>(null);
    const methodMiddle = ref(0);

    client
      // eslint-disable-next-line @typescript-eslint/camelcase
      .getEntries({ content_type: "method" })
      .then((res) => {
        methods.value = res.items;
        methods.value.sort(
          (a: any, b: any) => a.fields.position - b.fields.position
        );
        methodMiddle.value = Math.round(methods.value.length / 2) - 1;
      })
      .catch();

    return { methods, methodMiddle };
  }
};
